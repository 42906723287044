/*
    LOADING CIRCLE
 */

$base-line-height: 24px;
$base-line-height-small: 18px;
$base-line-height-large: 38px;
$white: rgb(255, 255, 255);
$dark: rgb(0,0,0);
$off-white: rgba($white, 0.2);
$off-dark: rgba($dark, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid $off-white;
  border-top-color: $white;
  animation: spin $spin-duration infinite linear;
  &.spinner-dark {
    border: .25rem solid $off-dark;
    border-top-color: $dark;
  }
  &.spinner-small {
    width: $base-line-height-small;
    height: $base-line-height-small;
    border-width: .15rem;
  }
  &.spinner-large {
    width: $base-line-height-large;
    height: $base-line-height-large;
    border-width: .45rem;
  }

}

img.grayscale {
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter:    grayscale(100%);
  -ms-filter:     grayscale(100%);
  -o-filter:      grayscale(100%);
  cursor: pointer;
  transition: all 300ms ease;
}
img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter:    grayscale(0%);
  -ms-filter:     grayscale(0%);
  -o-filter:      grayscale(0%);
}
a:focus, a:hover{
  text-decoration: none;
}
.d_inline {
  display: inline;
}
table tr.cart-row td.cart-row-product-image {
  width: 120px;
}
input[type="radio"], input[type="checkbox"] {
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
  outline: none;
}
input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: none;
}
.has-error input[type="checkbox"]+label:before, .has-error input[type="radio"]+label:before {
  border-color: red;
}
.auto-margin {
  margin: auto;
}
#sliderRevolutionArrowDown {
  background-color: rgba(255,255,255,0.8);
  -webkit-mask-image: url("../img/arrow_down.svg");
  mask-image: url("../img/arrow_down.svg");
  display: block;
  width: 150px;
  height: 77px;
  position: absolute;
  bottom: 20vw;
  left: 50%;
  margin-left: -75px;
  z-index: 100;
}

/* BOUNCE EFFECT */

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
  40% {-moz-transform: translateY(-30px);}
  60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
  40% {-o-transform: translateY(-30px);}
  60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
